import P from 'prop-types';
import * as Styled from './styles';
const Button = ({ children, rounded = true, textColor, background }) => {
    return (
        <Styled.Container rounded={rounded} textColor={textColor} background={background}>
            {children}
        </Styled.Container>
    );
};
export default Button;
Button.propTypes = {
    children: P.node.isRequired,
    textColor: P.string,
    background: P.string,
    rounded: P.bool
};