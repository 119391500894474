import P from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../global-styles';
import { theme } from '../theme';

const StylesProvider = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {children}
        </ThemeProvider>        
    );
};
StylesProvider.propTypes = {
    children: P.node.isRequired,
};
export default StylesProvider