import P from 'prop-types';
import * as Styled from './styles';
const AnimatedCircle = ({ text, description }) => {
    return (
        <Styled.Container>
            <Styled.Background />
            <Styled.Content>
                <Styled.BigText>
                    {text}
                </Styled.BigText>
                <Styled.SmallText>
                    {description}
                </Styled.SmallText>
            </Styled.Content>
        </Styled.Container>
    );
};
AnimatedCircle.propTypes = {
    children: P.node.isRequired,
};
export default AnimatedCircle;