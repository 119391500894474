import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import AnimatedCircle from '../components/AnimatedCircle';
import StylesProvider from "../styles/StylesProvider";
import Button from '../components/Button';

const IndexPage = () => (
  <StylesProvider>
    <div>
      <AnimatedCircle text={"Texto"} description={"descricao"} />
      <Button rounder={false}>Enviar</Button>
    </div>
  </StylesProvider>
)

export default IndexPage
