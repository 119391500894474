export const theme = {
    colors:{
        mainColor: "#140A9A", 
        secondaryColor: "#253FC8",
        terciaryColor: '#1B232A',
        quaternaryColor: '#FFC82F',
        primaryGray: '#A5ABAF',
        secondaryGray: '#D1D4D3',
    },
    fonts:{
        mainFont: 'Noto Sans',
        secondaryFont: 'Roboto'
    },
    spacings:{},
    transitions:{
        timing: {
            mainTiming: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)'
        }
    }
}