import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        background-size: cover;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 7rem;
        height: 7rem;
        border-radius: 100%;
        line-height: 18px;
    `}
`;
export const Background = styled.div`
    ${({ theme }) => css`
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: linear-gradient(${theme.colors.mainColor}, #000 35% );
        animation: rotating 2s linear infinite;
        @keyframes rotating {
            from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            }
            to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
            }
        }      
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        background-size: cover;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 85%;
        height: 85%;
        border-radius: 100%;
        background: #000;
        color: #fff;
        font-weight: bold;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    `}
`;
export const BigText = styled.span`
    ${({ theme }) => css`
        font-size: 20px;
    `}
`;
export const SmallText = styled.span`
    ${({ theme }) => css`
        font-size: 10px;
    `}
`;